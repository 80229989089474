<template>
  <div class="index">
    <div class="content">
      <Home @goStaring="goStaring" v-if="activeIndex === 0" />
      <Starting v-if="activeIndex === 1" />
    </div>
    <div class="tarbarBox">
      <div class="tarbar" @click="changePage(0)">
        <img src="@/assets/images/tarbar1_check.png" v-if="activeIndex === 0" alt="" />
        <img src="@/assets/images/tarbar1.png" v-else alt="" />
        <p>{{ $t("Home") }}</p>
      </div>
      <div class="tarbar2" @click="changePage(1)">
        <img src="@/assets/images/tarbar2.png" alt="" />
        <p>{{ $t("Starting") }}</p>
      </div>
      <div class="tarbar" @click="changePage(2)">
        <img src="@/assets/images/tarbar3_check.png" v-if="activeIndex === 2" alt="" />
        <img src="@/assets/images/tarbar3.png" v-else alt="" />
        <p>{{ $t("Records") }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import Home from "./components/home.vue";
import Starting from "./components/starting";

export default {
  components: {
    Home,
    Starting,
  },
  data() {
    return {
      activeIndex: 0,
    };
  },
  methods: {
    changePage(index) {
      if (index === 2) {
        this.$router.push("/records");
        return false;
      }
      this.activeIndex = index;
      sessionStorage.setItem("homeIndex", index);
    },
    goStaring() {
      this.changePage(1);
    },
  },
  created() {
    if (sessionStorage.getItem("homeIndex")) {
      this.activeIndex = parseInt(sessionStorage.getItem("homeIndex"));
    }
  },
};
</script>

<style lang="less">
.index {
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
  }
  .tarbarBox {
    width: 100%;
    height: 84px;
    position: fixed;
    z-index: 100;
    background: #3827c8;
    bottom: 0;
    left: 0;
    display: flex;
    .tarbar {
      flex: 1;
      flex-grow: 1;
      img {
        width: 34px;
        display: block;
        margin: 5px auto 0;
      }
      p {
        color: #fff;
        text-align: center;
        font-size: 12px;
      }
    }
    .tarbar2 {
      width: 76px;
      margin-top: -20px;
      img {
        width: 100%;
      }
      p {
        color: #fff;
        text-align: center;
        font-size: 12px;
        margin-top: -5px;
      }
    }
  }
}
</style>
