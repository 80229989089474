var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"withdrawHistory"},[_c('Header',{attrs:{"title":_vm.$t('WithdrawHistory')}}),_c('div',{staticClass:"content"},[_c('van-pull-refresh',{attrs:{"loading-text":_vm.$t('Loading'),"loosing-text":" ","pulling-text":" "},on:{"refresh":_vm.onRefresh},model:{value:(_vm.refreshing),callback:function ($$v) {_vm.refreshing=$$v},expression:"refreshing"}},[_c('van-list',{staticClass:"list",attrs:{"finished":_vm.finished,"loading-text":_vm.$t('Loading')},on:{"load":_vm.initPage},model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},_vm._l((_vm.dataList),function(item){return _c('div',{key:item.walletRecordId,staticClass:"box"},[_c('img',{staticStyle:{"width":"50px"},attrs:{"src":require("@/assets/images/slide2.png"),"alt":""}}),_c('div',{staticClass:"timeBox"},[_c('h2',[_vm._v(_vm._s(_vm.$t('Withdraw')))]),_c('p',[_vm._v(_vm._s(_vm._f("formatTime")(item.submitTime,"YMDHMS")))]),_c('p',{staticClass:"wrap"},[_vm._v(_vm._s(item.reason))])]),_c('div',{staticClass:"buttonBox"},[_c('div',{class:[
                  'button',
                  item.status === 'refuse'
                    ? 'bg_red'
                    : item.status === 'wait'
                    ? 'bg_yellow'
                    : '',
                ]},[_vm._v(" "+_vm._s(item.status)+" ")]),_c('p',[_vm._v(_vm._s((parseFloat(item.reallyAmount) / 100).toFixed(2)))])])])}),0)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }