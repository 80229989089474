<template>
	<div>
		<van-popup v-model="showSlide" :close-on-click-overlay="false" position="left"
			style="width: 325px; height: 100%">
			<div class="slidePop">
				<div class="header">
					<van-icon name="cross" color="#fff" size="20px" @click="closeSlide()" class="closeIcon" />
					<img src="@/assets/images/ripe.png" alt="" class="ripe" />
				</div>
				<div class="userBox">
					<div class="avatarBox" @click="goPage('/myInfo')">
						<img :src="userInfo.headImg" style="width: 78px; height: 78px" alt="" />
						<div v-if="userInfo && userInfo.vipLevel" class="level" :style="backgroundStyle(userInfo.vipLevel)"></div>
					</div>
					<div class="name">{{ userInfo.userName }}</div>
					<div class="invite" @click="copyed(userInfo.inviteCode)">
						{{ $t("InvitationCode") }}:{{ userInfo.inviteCode }}
					</div>
				</div>
				<div class="progressBox">
					<span>{{ $t("CredibilityValue") }}:</span>
					<div>
						<van-progress :percentage="parseFloat(userInfo.creditScore || 100)" stroke-width="3"
							color="#3827c8" />
					</div>
				</div>
				<div class="infoBox">
					<div class="info">
						<p>{{ $t("USDC") }} {{ userInfo.todayProfit }}</p>
						<p>{{ $t("Todayprofit") }}</p>
						<p class="tips">{{ $t("info18") }}</p>
					</div>
					<div class="info">
						<p style="margin-top: 20px">{{ $t("USDC") }} {{ userInfo.walletAmount }}</p>
						<p>{{ $t("WalletAmount") }}</p>
					</div>
				</div>
				<div class="buttonBox">
					<div class="button" @click="goPage('/deposit')" style="display: none;">
						<img src="@/assets/images/slide1.png" alt="" />
						<span>{{ $t("deposit") }}</span>
						<van-icon name="arrow" color="#fff" size="18px" />
					</div>
					<div class="button" @click="goPage('/withdraw')">
						<img src="@/assets/images/slide2.png" alt="" />
						<span>{{ $t("Withdraw") }}</span>
						<van-icon name="arrow" color="#fff" size="18px" />
					</div>
					<div class="button" @click="goPage('/myInfo')">
						<img src="@/assets/images/slide3.png" alt="" />
						<span>{{ $t("PersonalInfo") }}</span>
						<van-icon name="arrow" color="#fff" size="18px" />
					</div>
					<div class="button" @click="goPage('/transaction')">
						<img src="@/assets/images/slide4.png" alt="" />
						<span>{{ $t("Transaction") }}</span>
						<van-icon name="arrow" color="#fff" size="18px" />
					</div>
					<div class="button" @click="bindWallet()">
						<img src="@/assets/images/slide5.png" alt="" />
						<span>{{ $t("WalletUSDCBind") }}</span>
						<van-icon name="arrow" color="#fff" size="18px" />
					</div>
					<div class="button" @click="showSheet = true">
						<img src="@/assets/images/slide6.png" alt="" />
						<span>{{ $t("CustomerCare") }}</span>
						<van-icon name="arrow" color="#fff" size="18px" />
					</div>
					<div class="button" @click="showSheet2 = true">
						<img src="@/assets/images/lang.png" alt="" />
						<span>{{ $t("lang") }}</span>
						<van-icon name="arrow" color="#fff" size="18px" />
					</div>
					<div class="button" @click="getout()">
						<img src="@/assets/images/slide7.png" alt="" />
						<span>{{ $t("AccountLogout") }}</span>
						<van-icon name="arrow" color="#fff" size="18px" />
					</div>
				</div>
			</div>
		</van-popup>
		<van-popup v-model="showPop" round position="bottom">
			<div class="setPwd">
				<h2>
					{{ $t("info19") }}
					<van-icon name="cross" @click="showPop = false" class="iconClose" color="#666" />
				</h2>
				<div class="line">
					<span>{{ $t("SecurityPIN") }}</span>
					<div class="inputBox">
						<input :type="form.isSeePin ? 'text' : 'password'" class="input" placeholder="Type Password"
							v-model="form.pin" />
					</div>
					<img src="@/assets/images/see.png" @click="form.isSeePin = false" v-if="form.isSeePin"
						style="width: 30px" alt="" />
					<img src="@/assets/images/seeOff.png" @click="form.isSeePin = true" v-if="!form.isSeePin"
						style="width: 30px" alt="" />
				</div>
				<div class="line">
					<span>{{ $t("ConfirmSecurityPIN") }}</span>
					<div class="inputBox">
						<input :type="form.isSeeRePin ? 'text' : 'password'" class="input" placeholder="Type Password"
							v-model="form.rePin" />
					</div>
					<img src="@/assets/images/see.png" @click="form.isSeeRePin = false" v-if="form.isSeeRePin"
						style="width: 30px" alt="" />
					<img src="@/assets/images/seeOff.png" @click="form.isSeeRePin = true" v-if="!form.isSeeRePin"
						style="width: 30px" alt="" />
				</div>
				<div class="df_btn" @click="submitData()">{{ $t("Submit") }}</div>
			</div>
		</van-popup>
		<van-dialog width="90%" v-model="showDialog" title="" class="walletBox" :showConfirmButton="false">
			<h2>
				<span>{{ $t("WalletUSDCBind") }}</span>
				<img src="@/assets/images/close2.png" class="close" @click="showDialog = false" alt="" />
			</h2>
			<p>{{ $t("info36") }}</p>
			<div class="textareaBox">
				<textarea :placeholder="$t('info37')" v-model="USDCAddress" />
			</div>
			<p>{{ $t("RealName") }}</p>
			<div class="textareaBox" style="height: 40px">
				<textarea :placeholder="$t('info38')" v-model="realName" />
			</div>
			<p>{{ $t("Phone") }}</p>
			<div class="textareaBox" style="height: 40px">
				<textarea :placeholder="$t('info39')" v-model="phone" />
			</div>
			<div class="df_btn" @click="saveAddress()">{{ $t("Save") }}</div>
		</van-dialog>
		<van-action-sheet v-model="showSheet" :cancel-text="$t('Cancel')" close-on-click-action>
			<template #default="{ action }">
				<div v-for="(action, index) in actions" :key="index" @click="handleActionClick(action)"
					class="action-div">
					<img v-if="action.image" :src="action.image" class="action-image" alt="" />
					{{ action.name }}
				</div>
			</template>
		</van-action-sheet>
		<van-action-sheet v-model="showSheet2" :actions="actions2" :cancel-text="$t('Cancel')" close-on-click-action
			@select="selectSheet2" @cancel="showSheet2 = false" />
	</div>
</template>

<script>
	export default {
		data() {
			return {
				showDialog: false,
				showPop: false,
				showSlide: false,
				isSetPwd: false, //是否设置了PIN密码
				userInfo: {},
				form: {
					pin: "",
					rePin: "",
					isSeePin: false,
					isSeeRePin: false,
				},
				USDCAddress: "",
				realName: "",
				phone: "",
				showSheet: false,
				showSheet2: false,
				actions: [],
				actions2: [{
						name: "English",
						value: "en",
					},
					{
						name: "Español",
						value: "xb",
					},
					{
						name: "Deutsch",
						value: "de",
					},
				],
				bankName: "",
			};
		},
		methods: {
			backgroundStyle(vipLevel) {
			  if (!vipLevel) {
			    // 返回一个默认的背景或者不做任何设置
			    return {};
			  }
			  return {
			    background: `url(${require("@/assets/images/level" + vipLevel + ".png")}) 0% 0% / 35px 30px no-repeat`,
			  };
			},
			copyed(value) {
				var input = document.createElement("input"); // 创建input对象
				input.value = value; // 设置复制内容
				document.body.appendChild(input); // 添加临时实例
				input.select(); // 选择实例内容
				document.execCommand("Copy"); // 执行复制
				document.body.removeChild(input); // 删除临时实例
				this.toast(this.$t("CopySuccessfully"));
			},
			//客服
			selectSheet(opt) {
				window.open(opt.value, "_blank");
			},
			//语言
			selectSheet2(opt) {
				this.$i18n.locale = opt.value;
				localStorage.setItem("lang", opt.value);
				this.$EVENT.$emit("changeLang");
			},
			async saveAddress() {
				if (!this.USDCAddress) {
					this.showInfo(this.$t("info29"));
					return;
				}
				const res = await this.getAjax(
					"api/iishebajdnwfd/ccyewhsnlf/setUsdtAddress", {
						usdtAddress: this.USDCAddress,
						realName: this.realName,
						phone: this.phone,
					},
					"post"
				);
				if (res.success) {
					this.showInfo(this.$t("info23"));
					this.showDialog = false;
					this.USDCAddress = "";
				}
			},
			submitData() {
				if (!this.form.pin || !this.form.rePin) {
					this.showInfo(this.$t("info24"));
					return;
				}
				if (this.form.pin !== this.form.rePin) {
					this.showInfo(this.$t("info25"));
					return;
				}
				this.getAjax(
					"api/iishebajdnwfd/ccyewhsnlf/setSecPwd", {
						secPwd: this.form.pin,
					},
					"post"
				).then((res) => {
					this.showInfo(this.$t("info26"));
					this.showPop = false;
					this.isSetPwd = true;
				});
			},
			async bindWallet() {
				//查询是否设置了提款密码
				const res = await this.getAjax("api/iishebajdnwfd/ccyewhsnlf/isSetSecPwd", {}, "get");
				this.isSetPwd = res.data === "Not" ? false : true;
				if (this.isSetPwd) {
					this.USDCAddress = res.data;
					this.showDialog = true;
				} else {
					this.showPop = true;
				}
			},
			getout() {
				this.confirm(
					this.$t("info27"),
					() => {
						sessionStorage.removeItem("slide");
						this.$router.push("/login");
					},
					null, {
						title: this.$t("Tips"),
						confirmButtonText: this.$t("Logout"),
						confirmButtonColor: "#333",
						cancelButtonText: this.$t("Cancel"),
					}
				);
			},
			goPage(page) {
				this.$router.push(page);
			},
			closeSlide() {
				this.showSlide = false;
				sessionStorage.removeItem("slide");
			},
			async initPage() {
				this.getAjax("api/iishebajdnwfd/ccyewhsnlf/getBalance", {}, "get").then((res) => {
					this.userInfo = res.data;
					this.realName = res.data.realName;
					this.phone = res.data.phone;
				});

				//获取客服列表
				this.getAjax(
					"api/iwnaxhvbewk/owrnabxjh/getCustomer?url=" + window.location.host, {},
					"get"
				).then((res2) => {
					this.actions = res2.data
						.filter((e) => !!e.name)
						.map((item, index) => {
							let imageSrc;
							switch (item.name.toLowerCase()) {
								case "line":
									imageSrc = require("../assets/images/line.png");
									break;
								case "whatsapp":
									imageSrc = require("../assets/images/whatsapp.jpg");
									break;
								case "telegram":
									imageSrc = require("../assets/images/telegram.png");
									break;
								default:
									imageSrc = require(
									"../assets/images/whatsapp.jpg"); // Default image if none matches
							}
							return {
								name: item.name,
								image: imageSrc,
								value: item.value,
							};
						});
				});
			},
			handleActionClick(action) {
				// 执行页面跳转操作
				window.location.href = action.value;
			},
		},
		created() {
			if (sessionStorage.getItem("slide")) {
				this.showSlide = true;
			}
		},
		mounted() {
			this.initPage();
		},
	};
</script>

<style lang="less" scoped>
	/deep/ .van-dialog .van-dialog__footer {
		border-top: 0;
	}

	.walletBox {
		padding: 10px;
		color: #333;

		h2 {
			color: #70798a;
			text-align: center;
			position: relative;

			span {
				font-size: 18px;
			}

			.close {
				width: 13px;
				position: absolute;
				right: 10px;
				top: 7px;
			}
		}

		p {
			font-size: 12px;
			color: #fff;
			margin-top: 30px;
			margin-bottom: 10px;
		}

		.textareaBox {
			height: 80px;
			padding: 10px;
			border: 2px solid #3827c8;
			border-radius: 5px;
			box-sizing: border-box;

			textarea {
				width: 100%;
				height: 100%;
				resize: none;
			}
		}

		.df_btn {
			margin: 20px auto 0;
		}
	}

	.setPwd {
		padding: 15px;

		h2 {
			font-size: 15px;
			position: relative;
			color: #22b0c6;
			margin-bottom: 40px;

			.iconClose {
				position: absolute;
				right: 0;
				top: 4px;
				font-size: 16px;
				font-weight: bold;
			}
		}

		.line {
			height: 50px;
			display: flex;
			align-items: center;
			font-size: 13px;
			color: #777;
			border: 2px solid #c4e8ef;
			border-radius: 5px;
			margin-top: 20px;
			padding: 0 5px 0 10px;

			span {
				font-size: 14px;
				color: #22b0c6;
			}

			.inputBox {
				flex: 1;
				padding-right: 10px;

				input {
					text-align: right;
					width: 100%;
				}
			}
		}

		.df_btn {
			height: 40px;
			line-height: 38px;
			margin: 20px auto 10px;
		}
	}

	::-webkit-scrollbar {
		display: none;
	}

	.slidePop {
		background: linear-gradient(to bottom, #111111, #3827c8);
		min-height: 100vh;

		.header {
			width: 100%;
			height: 190px;
			padding: 10px;
			position: relative;

			.closeIcon {
				position: absolute;
				top: 10px;
				left: 15px;
			}

			.ripe {
				width: 170px;
				display: block;
				margin: 0 auto;
			}

			p {
				font-size: 12px;
				color: #97bec3;
				padding: 0 10px;
			}
		}

		.userBox {
			margin-top: -117px;
			position: relative;

			.avatarBox {
				width: 82px;
				height: 82px;
				background: url("@/assets/images/slide_head_bg.png") no-repeat;
				background-size: cover;
				margin: 0 auto;
				display: flex;
				align-items: center;
				justify-content: center;
				position: relative;

				img {
					border-radius: 100px;
				}

				.level {
					width: 35px;
					height: 30px;
					position: absolute;
					right: -30px;
					top: -10px;
					display: flex;
					justify-content: center;
					align-items: center;
					font-weight: bold;
					color: #21b5bf;
				}
			}

			.name {
				font-size: 16px;
				text-align: center;
				font-weight: bold;
				color: #fff;
				margin-top: 5px;
			}

			.invite {
				font-size: 12px;
				color: #fff;
				height: 24px;
				line-height: 22px;
				text-align: center;
				background: linear-gradient(to right, #5C258A, #3827C8);
				border-radius: 5px;
				margin: 10px auto;
				display: block;
				padding: 0 5px;
				width: 200px;
			}
		}

		.progressBox {
			display: flex;
			align-items: center;
			padding: 0 15px;
			margin-top: 20px;

			span {
				font-size: 12px;
				color: #fff;
				margin-right: 5px;
			}

			div {
				flex: 1;
			}
		}

		.infoBox {
			margin-top: 15px;
			display: flex;
			padding-left: 15px;

			.info {
				flex: 1;
				flex-grow: 1;
				height: 90px;
				background-image: linear-gradient(to bottom right, #5C258A, #3827C8);
				margin-right: 15px;
				border-radius: 5px;
				color: #fff;
				text-align: center;
				padding-top: 5px;
				box-shadow: 5px 5px 5px 0 rgba(248, 68, 255, 0.3);

				p {
					font-size: 13px;
					font-weight: bold;
				}

				.tips {
					font-size: 12px;
					transform: scale(0.8);
					width: 120%;
					text-align: left;
					margin-left: -7px;
					font-weight: normal;
				}
			}
		}

		.buttonBox {
			padding: 0 10px;
			margin-top: 20px;

			.button {
				height: 54px;
				border-bottom: 2px solid #666;
				display: flex;
				align-items: center;

				img {
					width: 30px;
					margin: 0 10px 0 5px;
				}

				span {
					flex: 1;
					color: #fff;
				}

				&:last-child {
					border-bottom: 0;
				}
			}
		}
	}
</style>