<template>
	<div class="withdrawHistory">
		<Header :title="$t('WithdrawHistory')" />
		<div class="content">
			<van-pull-refresh v-model="refreshing" @refresh="onRefresh" :loading-text="$t('Loading')" loosing-text=" "
				pulling-text=" ">
				<van-list v-model="loading" :finished="finished" @load="initPage" :loading-text="$t('Loading')"
					class="list">
					<div class="box" v-for="item in dataList" :key="item.walletRecordId">
						<img src="@/assets/images/slide2.png" style="width: 50px" alt="" />
						<div class="timeBox">
							<h2>{{$t('Withdraw')}}</h2>
							<p>{{ item.submitTime | formatTime("YMDHMS") }}</p>
							<p class="wrap">{{ item.reason }}</p>
						</div>
						<div class="buttonBox">
							<div :class="[
                  'button',
                  item.status === 'refuse'
                    ? 'bg_red'
                    : item.status === 'wait'
                    ? 'bg_yellow'
                    : '',
                ]">
								{{ item.status }}
							</div>
							<p>{{ (parseFloat(item.reallyAmount) / 100).toFixed(2) }}</p>

						</div>

					</div>
				</van-list>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	import Header from "@/components/Header.vue";
	export default {
		components: {
			Header
		},
		data() {
			return {
				dataList: [],
				loading: false,
				finished: false,
				refreshing: false,
				params: {
					pageNum: 1,
					pageSize: 10,
					total: 100,
				},
			};
		},
		methods: {
			onRefresh() {
				this.params = {
					pageNum: 1,
					pageSize: 10,
					total: 100,
				};
				this.finished = false;
				this.dataList = [];
				this.initPage();
			},
			async initPage() {
				//获取充值记录
				if (this.dataList.length < this.params.total) {
					const res = await this.getAjax(
						"api/iishebajdnwfd/ccyewhsnlf/cashoutRecord", {
							...this.params
						},
						"get"
					);
					this.loading = false;
					this.refreshing = false;
					this.params.total = res.data.total;
					res.data.list.forEach((e) => {
						this.dataList.push(e);
					});
					this.params.pageNum++;
				} else {
					this.finished = true;
				}
			},
		},
		mounted() {
			//获取充值记录
			this.dataList = [];
		},
	};
</script>

<style lang="less" scoped>
	.withdrawHistory {
		padding-top: 90px;

		.content {
			padding: 0 10px 10px;
			line-height: 1.6;

			.list {
				height: 100%;

				.box {
					padding: 15px 10px;
					display: flex;
					align-items: center;
					border-top: 1px solid #fff;

					&:first-child {
						border-top: 0;
					}

					img {
						margin-right: 5px;
					}

					.timeBox {
						flex: 1;

						h2 {
							font-size: 16px;
							margin-top: 5px;
							color: #fff;
						}

						p {
							font-size: 12px;
							margin-top: 5px;
							color: #e1e1e1;
						}

						.wrap {
							max-width: 200px;
							word-wrap: break-word;
						}
					}

					.buttonBox {
						padding-top: 10px;

						.button {
							width: 75px;
							height: 20px;
							display: flex;
							align-items: center;
							justify-content: center;
							color: #fff;
							font-size: 14px;
							background: #00cc66;
							border-radius: 3px;
						}

						.bg_red {
							background: #ff442b;
						}

						.bg_yellow {
							background: #facc01;
						}

						p {
							font-size: 16px;
							margin-top: 5px;
							color: #fff;
							font-weight: bold;
							text-align: right;
						}
					}
				}
			}
		}
	}
</style>