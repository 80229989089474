import Vue from 'vue';
import VueRouter from 'vue-router';
import login from '../views/login.vue';
import regeist from '../views/regeist.vue';
import home from '../views/home/index.vue';
import Agreement from '../views/agreement.vue';
import Records from '../views/records.vue';
import Tc from '../views/tc.vue';
import Event from '../views/event.vue';
import Faq from '../views/faq.vue';
import Cert from '../views/cert.vue';
import About from '../views/about.vue';
import VipLevel from '../views/vipLevel.vue';
import MyInfo from '../views/myInfo.vue';
import Deposit from '../views/deposit.vue';
import RechargeHistory from '../views/rechargeHistory.vue';
import Withdraw from '../views/withdraw.vue';
import WithdrawHistory from '../views/withdrawHistory.vue';
import Transaction from '../views/transaction.vue';
import ChangePwd from '../views/changePwd.vue';
import ChangePayPwd from '../views/changePayPwd.vue';
import ChangeFullName from '../views/changeFullName.vue';
import ChangePhone from '../views/changePhone.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'main',
    redirect: '/login',
    component: home,
    children: [
      {
        path: 'home',
        name: 'home',
        component: home,
        meta: {
          name: 'Home',
        },
      },
    ],
  }, {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      name: 'Login',
    },
  }, {
    path: '/regeist',
    name: 'regeist',
    component: regeist,
    meta: {
      name: 'Regeist',
    },
  }, {
    path: '/agreement',
    name: 'agreement',
    component: Agreement,
    meta: {
      name: 'Agreement',
    },
  }, {
    path: '/records',
    name: 'records',
    component: Records,
    meta: {
      name: 'Records',
    },
  }, {
    path: '/tc',
    name: 'tc',
    component: Tc,
    meta: {
      name: 'Terms And Conditions',
    },
  }, {
    path: '/event',
    name: 'event',
    component: Event,
    meta: {
      name: 'Event',
    },
  }, {
    path: '/faq',
    name: 'faq',
    component: Faq,
    meta: {
      name: 'FAQ',
    },
  }, {
    path: '/cert',
    name: 'cert',
    component: Cert,
    meta: {
      name: 'Certificate',
    },
  }, {
    path: '/about',
    name: 'about',
    component: About,
    meta: {
      name: 'About Us',
    },
  }, {
    path: '/vipLevel',
    name: 'vipLevel',
    component: VipLevel,
    meta: {
      name: 'VipLevel',
    },
  }, {
    path: '/myInfo',
    name: 'myInfo',
    component: MyInfo,
    meta: {
      name: 'MyInfo',
    },
  }, {
    path: '/deposit',
    name: 'deposit',
    component: Deposit,
    meta: {
      name: 'Deposit',
    },
  }, {
    path: '/rechargeHistory',
    name: 'rechargeHistory',
    component: RechargeHistory,
    meta: {
      name: 'RechargeHistory',
    },
  }, {
    path: '/withdraw',
    name: 'withdraw',
    component: Withdraw,
    meta: {
      name: 'Withdraw',
    },
  }, {
    path: '/withdrawHistory',
    name: 'withdrawHistory',
    component: WithdrawHistory,
    meta: {
      name: 'WithdrawHistory',
    },
  }, {
    path: '/transaction',
    name: 'transaction',
    component: Transaction,
    meta: {
      name: 'Transaction',
    },
  }, {
    path: '/changePwd',
    name: 'changePwd',
    component: ChangePwd,
    meta: {
      name: 'ChangePwd',
    },
  }, {
    path: '/changePayPwd',
    name: 'changePayPwd',
    component: ChangePayPwd,
    meta: {
      name: 'ChangePayPwd',
    },
  }
  , {
    path: '/changeFullName',
    name: 'changeFullName',
    component: ChangeFullName,
    meta: {
      name: 'ChangeFullName',
    },
  }
  , {
    path: '/changePhone',
    name: 'changePhone',
    component: ChangePhone,
    meta: {
      name: 'ChangePhone',
    },
  }
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
});

export default router;