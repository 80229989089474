<template>
	<div class="deposit">
		<Header :title="$t('deposit')" rightNav="History" @handleClickRightNav="handleClickRightNav" />
		<div class="content">
			<div class="accountBox">
				<h3>{{$t('accountAmount')}}</h3>
				<div class="money">
					<span>{{$t('USDC')}}</span>
					<em>{{ userInfo.walletAmount }}</em>
				</div>
			</div>
			<div class="moneyBox">
				<div :class="['item', item.money === choiceMoney ? 'on' : '']" @click="clickItem(item)"
					v-for="item in moneyList" :key="item.money">
					<h3>{{$t('USDC')}}</h3>
					<div class="value">{{ item.money }}</div>
				</div>
			</div>
			<div class="inputBox">
				<input type="number" class="input" v-model="choiceMoney" :placeholder="$t('DepositAmount')" />
			</div>
			<div class="df_btn" @click="depositSubmit()">{{$t('DepositNow')}}</div>
		</div>
		<van-action-sheet v-model="showSheet" cancel-text="Cancel" close-on-click-action>
			<template #default="{ action }">
				<div v-for="(action, index) in actions" :key="index" @click="handleActionClick(action)"
					class="action-div">
					<img v-if="action.image" :src="action.image" class="action-image" alt="">
					{{ action.name }}
				</div>
			</template>
		</van-action-sheet>
	</div>
</template>

<script>
	import Header from "@/components/Header.vue";
	export default {
		components: {
			Header
		},
		data() {
			return {
				choiceMoney: "",
				userInfo: {},
				moneyList: [{
						money: "100.00"
					},
					{
						money: "500.00"
					},
					{
						money: "1000.00"
					},
				],
				showSheet: false,
				actions: [{
					name: "Ripemedia CS"
				}],
			};
		},
		methods: {
			handleClickRightNav() {
				this.$router.push('/rechargeHistory');
			},
			clickItem(item) {
				this.choiceMoney = item.money;
			},
			depositSubmit() {
				if (!this.choiceMoney) {
					this.showInfo(this.$t('info28'));
					return;
				}
				this.showSheet = true
			},
			selectSheet(opt) {
				window.open(opt.value, '_blank');
			},
		},
		async mounted() {
			//获取用户信息
			this.getAjax("api/iishebajdnwfd/ccyewhsnlf/getBalance", {}, "get").then((res) => {
				this.hideLoading();
				this.userInfo = res.data;
			});
			//获取客服列表
			this.getAjax('api/iwnaxhvbewk/owrnabxjh/getCustomer?url=' + window.location.host, {}, 'get')
				.then(res2 => {
					console.log(res2)
					this.actions = res2.data
						.filter(e => !!e.name)
						.map((item, index) => {
							let imageSrc;
							switch (item.name.toLowerCase()) {
								case 'line':
									imageSrc = require('../assets/images/line.png');
									break;
								case 'whatsapp':
									imageSrc = require('../assets/images/whatsapp.jpg');
									break;
								case 'telegram':
									imageSrc = require('../assets/images/telegram.png');
									break;
								default:
									imageSrc = require(
										'../assets/images/whatsapp.jpg'); // Default image if none matches
							}
							return {
								name: item.name,
								image: imageSrc,
								value: item.value
							};
						});
				});
		},
	};
</script>

<style lang="less" scoped>
	.deposit {
		padding-top: 90px;

		.content {
			padding: 0 10px 10px;
			line-height: 1.6;

			.accountBox {
				width: 100%;
				height: 135px;
				background: linear-gradient(to bottom right, #5C258A, #3827C8);
				border-radius: 10px;
				padding: 10px;
				box-sizing: border-box;
				box-shadow: 5px 5px 5px 0 rgba(248, 68, 255, 0.3);

				h3 {
					color: #fff;
					margin-top: 20px;
					font-size: 12px;
					font-weight: normal;
				}

				.money {
					color: #fff;
					font-weight: bold;
					margin-top: 20px;

					span {
						font-size: 15px;
					}

					em {
						font-size: 22px;
						margin-left: 10px;
					}
				}
			}

			.moneyBox {
				margin-top: 20px;
				display: flex;

				.item {
					flex: 1;
					flex-grow: 1;
					height: 74px;
					border: 1px solid #fff;
					border-radius: 5px;
					padding: 10px 15px;
					box-sizing: border-box;
					color: #fff;
					margin-right: 5px;

					&:last-child {
						margin-right: 0;
					}

					h3 {
						font-weight: normal;
						font-size: 12px;
					}

					.value {
						padding-left: 15px;
						font-weight: bold;
						font-size: 16px;
					}
				}

				.on {
					background: #fff;
					border: 1px solid #fff;
					color: #3827c8;
				}
			}

			.inputBox {
				height: 46px;
				border-radius: 8px;
				padding: 0 10px;
				background: #d8f4f3;
				margin-top: 10px;

				.input {
					height: 100%;
					width: 100%;
					color: #039f98;
					font-size: 16px;
					background: #d8f4f3;
					outline: none;
					border: 0;
				}
			}
		}
	}
</style>