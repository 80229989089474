<template>
  <div class="vipLevel">
    <Header :title="$t('VIPLevel')" />
    <div class="content">
      <div class="box" v-for="item in vipData" :key="item.vipLevelId">
        <img
          src="@/assets/images/current.png"
          class="current"
          alt=""
          v-if="myVip == item.vipLevelId"
        />
        <img
          :src="require('@/assets/images/level' + item.vipLevelId + '.png')"
          alt=""
          class="levelImg"
        />
        <div class="infoBox" v-if="lang === 'en'">
          <h2>{{ item.vipName }}</h2>
          <h3>Minimum reset {{ item.vipPrice }}USDC</h3>
          <p>VIP {{ item.vipLevelId }} Enhance software</p>
          <ul>
            <li>{{ item.dayMaxCashoutCount }} withdrawals per day</li>
            <li>Maximum of {{ item.remark }} groups of orders per day</li>
            <li>
              Receive a set of {{ item.taskCount }}/{{ item.taskCount }} software enhacement
            </li>
            <li>Maximum {{ item.taskCount * item.remark }} orders per day</li>
            <li>Profit of {{ item.pointReward }}% per software</li>
          </ul>
        </div>
        <div class="infoBox" v-if="lang === 'xb'">
          <h2>{{ item.vipName }}</h2>
          <h3>Restablecer mínimo {{ item.vipPrice }}USDC</h3>
          <p>VIP {{ item.vipLevelId }} Enhance software</p>
          <ul>
            <ul>
              <li>{{ item.dayMaxCashoutCount }} retiros diarios</li>
              <li>Hasta {{ item.remark }} grupos de pedidos diarios</li>
              <li>Hasta tres grupos de pedidos al día reciben un grupo de {{ item.taskCount }}/{{ item.taskCount }}tareas de datos de aplicaciones</li>
              <li>Hasta {{ item.taskCount * item.remark }} pedidos diarios</li>
              <li>Beneficio del {{ item.pointReward }}% por solicitud</li>
            </ul>
          </ul>
        </div>
		<div class="infoBox" v-if="lang === 'de'">
		    <h2>{{ item.vipName }}</h2>
		    <h3>Mindestens {{ item.vipPrice }} USDC zurücksetzen</h3>
		    <p>VIP {{ item.vipLevelId }} Enhance software</p>
		    <ul>
		        <li>{{ item.dayMaxCashoutCount }} Abhebungen pro Tag</li>
		        <li>Maximal {{ item.remark }} Gruppen von Bestellungen pro Tag</li>
		        <li>
		            Erhalten Sie einen Satz von {{ item.taskCount }}/{{ item.taskCount }} App-Datenaufgaben
		        </li>
		        <li>Maximal {{ item.taskCount * item.remark }} Bestellungen pro Tag</li>
		        <li>Gewinn von {{ item.pointReward }}% pro Anwendung</li>
		    </ul>
		</div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {
      myVip: 1, //我的VIP等级
      vipData: [],
      lang: localStorage.getItem("lang") || "en",
    };
  },
  methods: {},
  mounted() {
    this.getAjax("api/iishebajdnwfd/ccyewhsnlf/getBalance", {}, "get").then((res) => {
      this.userInfo = res.data;
      this.myVip = this.userInfo.vipLevel;
      //获取VIP等级信息
      this.getAjax("api/vipshjwnzyq/mmxnhsyyrb/page", {}, "get").then((res) => {
        console.log(res);
        this.vipData = res.data.list;
      });
    });
  },
};
</script>

<style lang="less" scoped>
.vipLevel {
  padding-top: 90px;

  .content {
    padding: 0 15px 15px;
    line-height: 1.6;

    .box {
      border-bottom: 2px solid #fff;
      padding: 10px;
      display: flex;
      align-items: center;
      position: relative;

      .current {
        width: 60px;
        position: absolute;
        right: 0;
        top: 10px;
      }

      &:last-child {
        border-bottom: 0;
      }

      .levelImg {
        width: 75px;
      }

      .infoBox {
        flex: 1;
        padding-left: 5px;

        h2 {
          font-size: 14px;
        }

        h3 {
          font-size: 14px;
          color: #fff;
        }

        p {
          font-size: 13px;
          color: #fff;
        }

        ul {
          li {
            font-size: 12px;
            color: #fff;

            &::before {
              content: "";
              width: 4px;
              height: 4px;
              background: #fff;
              border-radius: 100px;
              display: inline-block;
              vertical-align: middle;
              margin-right: 3px;
            }
          }
        }
      }
    }
  }
}
</style>