<template>
  <div class="agreement">
    <Header :title="$t('agreement')" />
    <div class="content">
      <img :src="imgUrl" alt="" v-if="imgUrl" />
      <div style="font-size:16px;" v-html="info.replace(/\n/g,'<br/>').replace(/\r/g,'<br/>')"></div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: { Header },
  data() {
    return {
      showSlide: true,
      info:'',
      imgUrl: "",
    };
  },
  methods: {},
  mounted() {
    this.getAjax('api/iwnaxhvbewk/owrnabxjh/info',{},'get').then(res=>{
        this.info = res.data.agreementText;
        this.imgUrl = res.data.agreementImg;
    })
  },
};
</script>

<style lang="less" scoped>
.agreement{
    padding-top:90px;
    .content{
        padding:0 15px 15px;
        line-height: 1.6;
        img{
      width: 100%;
      display: block;
    }
    }
}
</style>