<template>
	<div class="starting">
		<div class="head">
			<img src="@/assets/images/menu.png" alt="" class="menu" @click="handleClickMenu()" />
			<div class="title">{{ $t("Starting") }}</div>
		</div>
		<div class="content">
			<h1>{{ $t("info14") }}</h1>
			<div class="lunboBox">
				<!-- 注意：需要给父容器一个height和:data='Array’和overfolw:hidden;左右滚动需要给ul容器一个初始化 css width。 -->
				<div v-show="showLunbo" style="overflow: hidden; width: 100%; height: 280px; display: flex">
					<vue-seamless-scroll :data="listData" :class-option="defaultOption" style="width: 400px">
						<ul class="ul-scoll" id="imgBox">
							<li v-for="(item, index) in listData" :key="index">
								<img :src="item.imgUrl" @click="goApp(item)" />
							</li>
						</ul>
					</vue-seamless-scroll>
				</div>
			</div>
			<div class="appBox">
				<div v-for="item in 15" :key="item" class="apps">
					<img :src="require('@/assets/images/' + item + '.png')" alt="" class="app" />
				</div>
			</div>
			<div class="level">
				<img v-if="orderInfo.vipLevel === '1'" src="@/assets/images/level1.png" alt="" />
				<img v-if="orderInfo.vipLevel === '2'" src="@/assets/images/level2.png" alt="" />
				<img v-if="orderInfo.vipLevel === '3'" src="@/assets/images/level3.png" alt="" />
				<img v-if="orderInfo.vipLevel === '4'" src="@/assets/images/level4.png" alt="" />
				<span>{{ orderInfo.fanDian }}% {{ $t("profit") }}</span>
			</div>
			<div class="df_btn">
				<span class="num">({{ orderInfo.todayCount }}/{{ orderInfo.maxCount }})</span>
				<span @click="startOrder()" class="num2">{{ $t("Start") }}</span>
			</div>
			<div v-if="showGifAnimation" class="gif-animation-wrapper">
				<img src="@/assets/images/loading.gif" alt="Loading..." />
			</div>
			<div class="jieshaoBox">
				<div class="item">
					<span>{{ $t("info35") }}</span>
					<span class="red">{{ userInfo.todayProfit }} {{ $t("USDC") }}</span>
				</div>
				<div class="item">
					<span>{{ $t("Amounttobehandled") }}</span>
					<span class="red">{{
              (Number(userInfo.walletAmount) + Number(userInfo.freezeBalance)).toFixed(2)
            }}
						{{ $t("USDC") }}</span>
				</div>
				<div class="item">
					<span>{{ $t("Orderquantity") }}</span>
					<span class="red">{{ orderInfo.todayCount }}</span>
				</div>
				<div class="item">
					<span>{{ $t("AvailableBalance") }}</span>
					<span class="red">{{ userInfo.walletAmount }} {{ $t("USDC") }}</span>
				</div>
				<div class="item">
					<span>{{ $t("ALL") }}</span>
					<span class="red">{{ orderInfo.maxCount }}</span>
				</div>
				<div class="item">
					<span>{{ $t("Undone") }}</span>
					<span class="red">{{ uncompletedOrderCount }}</span>
				</div>
			</div>
			<div class="tips">
				<div class="title">{{ $t("ImportantNotice") }}</div>
				<p>{{ $t("BusinessHours") }} {{ times.startTime }} - {{ times.endTime }}</p>
				<p>{{ $t("info17") }}</p>
			</div>
		</div>
		<Slide ref="slide" />
		<van-popup v-model="showPop" round position="bottom">
			<div class="task">
				<h2>
					{{ $t("RatingSubmission") }}
					<van-icon name="cross" @click="showPop = false" class="iconClose" color="#666" />
				</h2>
				<img :src="info.imgUrl" alt="" style="width: 135px" />
				<h3>{{ info.title }}</h3>
				<div class="money">
					<div class="item">
						<div class="name">{{ $t("Totalamount") }}</div>
						<div class="value">
							{{ $t("USDC") }} {{ info.unitPrice }} * {{ info.productCount }} =
							{{ info.totalAmount }}
						</div>
					</div>
					<div class="item">
						<div class="name">{{ $t("Profit") }}</div>
						<div class="value">{{ $t("USDC") }} {{ info.profit }}</div>
					</div>
				</div>
				<div class="line">
					<span>{{ $t("Creationtime") }}</span>
					<em>{{ info.createTime }}</em>
				</div>
				<div class="line">
					<span>{{ $t("RatingNo") }}.</span>
					<em>{{ info.recordId }}</em>
				</div>
				<div class="df_btn" @click="submitTask()">{{ $t("Submit") }}</div>
			</div>
		</van-popup>
	</div>
</template>

<script>
	import vueSeamlessScroll from "vue-seamless-scroll";
	import Slide from "@/components/slide.vue";
	export default {
		components: {
			vueSeamlessScroll,
			Slide,
		},
		data() {
			return {
				listData: [{}, {}],
				uncompletedOrderCount: 0,
				showPop: false,
				showLunbo: false,
				info: {},
				orderInfo: {}, //接单信息
				userInfo: {},
				times: {
					startTime: "",
					endTime: "",
				}, //营业时间
				showGifAnimation: false,
			};
		},
		computed: {
			defaultOption() {
				return {
					step: 1, // 数值越大速度滚动越快
					limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
					hoverStop: false, // 是否开启鼠标悬停stop
					direction: 2, // 0向下 1向上 2向左 3向右
					openWatch: false, // 开启数据实时监控刷新dom
					singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
					singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
					waitTime: 0, // 单步运动停止的时间(默认值1000ms)
				};
			},
		},
		methods: {
			async startOrder() {
				// 显示gif动画
				this.showGifAnimation = true;

				// 使用setTimeout在4秒后执行以下操作
				setTimeout(async () => {
					const res = await this.getAjax("api/iishebajdnwfd/ccyewhsnlf/takeOrder", {}, "get");
					this.info = res.data;
					this.initPage();

					// 隐藏gif动画
					this.showGifAnimation = false;

					this.showPop = true;
				}, 3000);
			},
			goApp(item) {
				window.open(item.clickUrl, "_blank");
			},
			handleClickMenu() {
				sessionStorage.setItem("slide", true);
				this.$refs.slide.showSlide = true;
				this.$refs.slide.initPage();
			},
			submitTask() {
				this.showPop = false;
				this.getAjax("api/iishebajdnwfd/ccyewhsnlf/subOrder", {}, "get").then(() => {
					// this.showInfo("Submitted successfully");
					this.initPage();
				});
			},
			initPage() {
				//获取用户接单数量信息
				this.getAjax("api/iishebajdnwfd/ccyewhsnlf/userOrder/info", {}, "get").then((res) => {
					this.orderInfo = res.data;
				});
				//余额查询接口
				this.getAjax("api/iishebajdnwfd/ccyewhsnlf/getBalance", {}, "get").then((res2) => {
					this.userInfo = res2.data;
				});
			},
			taskOrders() {
				this.getAjax("api/iishebajdnwfd/ccyewhsnlf/taskOrders", {}, "get")
					.then((res) => {
						if (res.data && res.data.list) {
							const uncompleted = res.data.list.filter(
								(order) => order.status !== "completed"
							);
							this.uncompletedOrderCount = uncompleted.length;
						} else {
							console.error("API response is not in the expected format:", res.data);
						}
					})
					.catch((err) => {
						console.error("Error fetching task orders:", err);
					});
			},
		},
		mounted() {
			this.getAjax("api/iwnaxhvbewk/owrnabxjh/page", {}, "get").then((res) => {
				this.defaultOption.limitMoveNum = res.data.list.length;
				this.listData = res.data.list;
				this.showLunbo = true;
			});
			this.initPage();
			this.taskOrders();
			//获取营业时间
			this.getAjax("api/iwnaxhvbewk/owrnabxjh/business", {}, "get").then((res) => {
				this.times = res.data;
			});
		},
	};
</script>

<style scoped lang="less">
	.gif-animation-wrapper {
		position: fixed;
		/* 使动画定位在屏幕的中央 */
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.5);
		/* 半透明背景 */
		display: flex;
		/* 使用flex布局居中内容 */
		align-items: center;
		justify-content: center;
	}

	.gif-animation-wrapper img {
		max-width: 200px;
		/* 限制GIF的大小 */
		max-height: 200px;
	}

	@keyframes scale {

		0%,
		100% {
			width: 65px;
			height: 65px;
		}

		20% {
			width: 60px;
			height: 45px;
		}

		30% {
			width: 55px;
			height: 40px;
		}

		40% {
			width: 70px;
			height: 70px;
		}

		50% {
			width: 60px;
			height: 60px;
		}

		60% {
			width: 65px;
			height: 65px;
		}
	}

	.seamless-warp {
		height: 280px;
	}

	.starting {
		height: 100%;
		overflow: hidden;
		overflow-y: auto;

		.head {
			width: 100%;
			height: 90px;
			background: url("@/assets/images/nav_bg.png") 0 -380px no-repeat;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 10;

			.menu {
				width: 28px;
				position: absolute;
				top: 10px;
				left: 10px;
			}

			.title {
				color: #fff;
				font-size: 18px;
				font-weight: bold;
				padding-top: 13px;
				padding-left: 50px;
			}

			.ripe {
				width: 76px;
				margin: 10px auto;
				display: block;
			}
		}

		.content {
			padding: 90px 0 120px;

			h1 {
				color: #fff;
				text-align: center;
				font-size: 20px;
				margin-bottom: 10px;
			}

			.lunboBox {
				width: 100%;
				height: 280px;
				overflow: hidden;

				.ul-scoll {
					width: 100%;
					height: 280px;
					white-space: nowrap;
					display: flex;

					li {
						width: 230px;
						height: 280px;

						img {
							height: 280px;
							width: 230px;
						}
					}
				}
			}

			.appBox {
				overflow: hidden;
				margin-top: 20px;

				.apps {
					width: 20%;
					float: left;
					height: 65px;
					display: flex;
					align-items: center;
					justify-content: center;

					.app {
						width: 65px;
						height: 65px;
						animation: scale 3s infinite;
					}

					&:nth-child(1) {
						.app {
							animation-delay: 0.2s;
						}
					}

					&:nth-child(2) {
						.app {
							animation-delay: 0.4s;
						}
					}

					&:nth-child(3) {
						.app {
							animation-delay: 0.6s;
						}
					}

					&:nth-child(4) {
						.app {
							animation-delay: 0.8s;
						}
					}

					&:nth-child(5) {
						.app {
							animation-delay: 1s;
						}
					}

					&:nth-child(6) {
						.app {
							animation-delay: 1.2s;
						}
					}

					&:nth-child(7) {
						.app {
							animation-delay: 1.4s;
						}
					}

					&:nth-child(8) {
						.app {
							animation-delay: 1.6s;
						}
					}

					&:nth-child(9) {
						.app {
							animation-delay: 1.8s;
						}
					}

					&:nth-child(10) {
						.app {
							animation-delay: 2s;
						}
					}

					&:nth-child(11) {
						.app {
							animation-delay: 2.2s;
						}
					}

					&:nth-child(12) {
						.app {
							animation-delay: 2.4s;
						}
					}

					&:nth-child(13) {
						.app {
							animation-delay: 2.6s;
						}
					}

					&:nth-child(14) {
						.app {
							animation-delay: 2.8s;
						}
					}

					&:nth-child(15) {
						.app {
							animation-delay: 3s;
						}
					}
				}
			}

			.num {
				margin-left: 20px;
				font-size: 15px;
			}

			.num2 {
				padding: 0 5px;
				background-color: #cec866;
				height: 40px;
				line-height: 40px;
				margin: 5px;
				border-radius: 5px;
			}

			.level {
				display: flex;
				align-items: center;
				justify-content: center;
				margin-top: 15px;

				img {
					width: 30px;
					margin-right: 5px;
				}

				span {
					color: #fff;
					font-size: 13px;
					font-weight: bold;
				}
			}

			.df_btn {
				margin: 15px;
				display: flex;
				justify-content: space-between;
			}

			.red {
				color: #de5559;
			}

			.jieshaoBox {
				background: #fff;
				padding: 0 15px;
				margin: 15px;
				display: flex;
				flex-direction: column;
				align-items: center;
				border-radius: 10px;

				.item {
					border-bottom: 1px solid #3827c8;
					display: flex;
					justify-content: space-between;
					padding: 0 15px;
					width: 100%;
					color: #666;
					height: 50px;
					line-height: 50px;
					font-weight: bold;

					img {
						width: 70px;
						margin: 15px auto;
						display: block;
					}

					h3 {
						text-align: center;
						color: #2ca9c8;
						font-weight: normal;
					}

					h4 {
						text-align: center;
						color: #2ca9c8;
						margin-top: 15px;
					}

					p {
						font-size: 12px;
						transform: scale(0.9);
						width: 120%;
						margin-left: -10%;
						text-align: center;
						color: #777;
					}
				}
			}

			.tips {
				padding: 15px;

				.title {
					font-weight: bold;
				}

				p {
					font-size: 12px;
					color: #999;
					line-height: 1.8;

					&:before {
						content: "";
						width: 1px;
						height: 1px;
						display: inline-block;
						background: #888;
						border-radius: 100%;
						vertical-align: middle;
						margin-right: 5px;
					}
				}
			}
		}

		.task {
			padding: 15px;

			h2 {
				text-align: center;
				font-size: 15px;
				position: relative;
				color: #22b0c6;

				.iconClose {
					position: absolute;
					right: 0;
					top: 4px;
					font-size: 16px;
					font-weight: bold;
				}
			}

			img {
				display: block;
				margin: 20px auto 10px;
			}

			h3 {
				text-align: center;
				color: #777;
			}

			.money {
				display: flex;
				margin-top: 15px;
				border-bottom: 1px solid #22b0c6;
				padding-bottom: 20px;

				.item {
					flex: 1;
					flex-flow: 1;
					color: #22b0c6;

					.name {
						font-size: 12px;
						text-align: center;
					}

					.value {
						font-weight: bold;
						font-size: 16px;
						text-align: center;
						margin-top: 5px;
					}
				}
			}

			.line {
				height: 50px;
				display: flex;
				align-items: center;
				font-size: 13px;
				color: #777;
				border-bottom: 1px solid #22b0c6;

				span {
					flex: 1;
					font-size: 12px;
				}
			}

			.df_btn {
				height: 40px;
				line-height: 38px;
				margin: 20px auto 10px;
			}
		}
	}
</style>