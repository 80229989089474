<template>
	<div class="home">
		<div class="head">
			<img src="@/assets/images/menu.png" alt="" class="menu" @click="handleClickMenu()" />
			<img src="@/assets/images/ripe.png" alt="" class="ripe" />
		</div>
		<div class="content">
			<div class="userInfo">
				<div v-if="userInfo && userInfo.vipLevel" class="level" :style="backgroundStyle(userInfo.vipLevel)">
				</div>
				<div class="welcome">{{ $t("HiWelcome") }}</div>
				<div class="name">{{ userInfo.userName }}</div>
			</div>
			<div class="tipsBox">
				<img src="@/assets/images/lingdang.png" alt="" />
				<p>
					<marquee><span style="font-size: 12px">{{ $t("WelcometoZazz") }}</span></marquee>
				</p>
			</div>
			<div class="buttonBox">
				<div class="itemBox" @click="goStaring()">
					<div class="imgBox">
						<img src="@/assets/images/home_button1.png" alt="" />
					</div>
					<p>{{ $t("Starting") }}</p>
				</div>
				<div class="itemBox" @click="goPage('/cert')">
					<div class="imgBox">
						<img src="@/assets/images/home_button2.png" alt="" />
					</div>
					<p>{{ $t("cert") }}</p>
				</div>
				<div class="itemBox" @click="goPage('/withdraw')">
					<div class="imgBox">
						<img src="@/assets/images/home_button3.png" alt="" />
					</div>
					<p>{{ $t("Withdraw") }}</p>
				</div>
				<div class="itemBox" @click="goPage('/deposit')" style="display: none;">
					<div class="imgBox">
						<img src="@/assets/images/home_button4.png" alt="" />
					</div>
					<p>{{ $t("deposit") }}</p>
				</div>
			</div>
			<div class="levelBox">
				<div class="bg">
					<h2>
						<span>{{ $t("VIPLevel") }}</span>
						<a href="javascript:;" @click="goPage('/vipLevel')">{{ $t("ViewMore") }} ></a>
					</h2>
					<div class="level">
						<div :class="['item', userInfo.vipLevel === '1' ? 'on' : '']">
							<img src="@/assets/images/level1.png" class="lv" alt="" />
							<img src="@/assets/images/lock.png" class="lock" alt="" />
						</div>
						<div :class="['item', userInfo.vipLevel === '2' ? 'on' : '']">
							<img src="@/assets/images/level2.png" class="lv" alt="" />
							<img src="@/assets/images/lock.png" class="lock" alt="" />
						</div>
						<div :class="['item', userInfo.vipLevel === '3' ? 'on' : '']">
							<img src="@/assets/images/level3.png" class="lv" alt="" />
							<img src="@/assets/images/lock.png" class="lock" alt="" />
						</div>
						<div :class="['item', userInfo.vipLevel === '4' ? 'on' : '']">
							<img src="@/assets/images/level4.png" class="lv" alt="" />
							<img src="@/assets/images/lock.png" class="lock" alt="" />
						</div>
					</div>
				</div>
				<div class="textBox" v-if="lang === 'en'">
					<h3>VIP Level : {{ vipInfo.vipName }}</h3>
					<p>{{ vipInfo.vipPrice }} USDC</p>
					<ul>
						<li>{{ vipInfo.dayMaxCashoutCount }} withdrawals per day</li>
						<li>
							Maximum of {{ vipInfo.remark }} sets of missions per day, total of
							{{ vipInfo.taskCount * vipInfo.remark }} missions.
						</li>
						<li>Profit of {{ vipInfo.pointReward }}% per application</li>
						<li>Permanent membership</li>
					</ul>
				</div>
				<div class="textBox" v-if="lang === 'xb'">
					<h3>Nivel VIP: {{ vipInfo.vipName }}</h3>
					<p>{{ vipInfo.vipPrice }} Dólares</p>
					<ul>
						<li>{{ vipInfo.dayMaxCashoutCount }} retiros diarios</li>
						<li>
							Hasta {{ vipInfo.remark }} grupos de tareas al día, un total de
							{{ vipInfo.taskCount * vipInfo.remark }} tareas
						</li>
						<li>El beneficio por solicitud es del {{ vipInfo.pointReward }}%</li>
						<li>Miembros permanentes</li>
					</ul>
				</div>
				<div class="textBox" v-if="lang === 'de'">
					<h3>VIP-Stufe: {{ vipInfo.vipName }}</h3>
					<p>{{ vipInfo.vipPrice }} USDC</p>
					<ul>
						<li>{{ vipInfo.dayMaxCashoutCount }} Abhebungen pro Tag</li>
						<li>
							Maximal {{ vipInfo.remark }} Sets von Aufgaben pro Tag, insgesamt
							{{ vipInfo.taskCount * vipInfo.remark }} Aufgaben.
						</li>
						<li>Gewinn von {{ vipInfo.pointReward }}% pro Anwendung</li>
						<li>Permanente Mitgliedschaft</li>
					</ul>
				</div>
			</div>
			<div class="otherBox">
				<div class="item" @click="goPage('/tc')">
					<img src="@/assets/images/sm1.png" alt="" />
					<p>{{ $t("T&C") }}</p>
				</div>
				<div class="item" @click="goPage('/event')">
					<img src="@/assets/images/sm2.png" alt="" />
					<p>{{ $t("Event") }}</p>
				</div>
				<div class="item" @click="goPage('/faq')">
					<img src="@/assets/images/sm3.png" alt="" />
					<p>{{ $t("FAQ") }}</p>
				</div>
				<div class="item" @click="goPage('/about')">
					<img src="@/assets/images/sm4.png" alt="" />
					<p>{{ $t("aboutUs") }}</p>
				</div>
			</div>
		</div>
		<Slide ref="slide" />
		<van-overlay :show="showOverlay" z-index="1000">
			<div class="notice">
				<div class="imgBox">
					<img src="@/assets/images/close.png" class="closeImg" @click="closeNotice()" alt="" />
					<img v-if="noticeInfo.imgUrl" :src="noticeInfo.imgUrl" alt="" class="noticeImg" />
					<div class="noticeText" v-html="noticeInfo.body" v-if="!noticeInfo.imgUrl && noticeInfo.body"></div>
				</div>
			</div>
		</van-overlay>
	</div>
</template>

<script>
	import Slide from "@/components/slide.vue";
	export default {
		components: {
			Slide
		},
		data() {
			return {
				showOverlay: false,
				noticeInfo: {
					body: "",
					imgUrl: "",
				},
				userInfo: {},
				vipData: [],
				vipInfo: {},
				lang: localStorage.getItem("lang") || "en",
			};
		},
		methods: {
			backgroundStyle(vipLevel) {
				if (!vipLevel) {
					// 返回一个默认的背景或者不做任何设置
					return {};
				}
				return {
					background: `url(${require("@/assets/images/level" + vipLevel + ".png")}) 0% 0% / 50px 45px no-repeat`,
				};
			},
			handleClickMenu() {
				sessionStorage.setItem("slide", true);
				this.$refs.slide.showSlide = true;
				this.$refs.slide.initPage();
			},
			goPage(page) {
				this.$router.push(page);
			},
			goStaring() {
				this.$emit("goStaring");
			},
			closeNotice() {
				localStorage.removeItem("firstLogin");
				this.showOverlay = false;
			},
		},
		mounted() {
			const isFirstLogin = localStorage.getItem("firstLogin");
			//第一次登录，获取公告详情
			if (isFirstLogin) {
				this.getAjax("api/iwnaxhvbewk/owrnabxjh/notice", {}, "get").then((res) => {
					if (!res.data) return;
					this.noticeInfo = res.data;
					if (res.data.body || res.data.imgUrl) {
						this.showOverlay = true;
					}
				});
			}
			this.userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
			// 获取最新的余额信息
			this.getAjax("api/iishebajdnwfd/ccyewhsnlf/getBalance", {}, "get").then((res) => {
				if (res.data) {
					this.userInfo.vipLevel = res.data.vipLevel;

					// 更新 localStorage 中的 userInfo
					localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
				}
			});
			//获取VIP等级信息
			this.getAjax("api/vipshjwnzyq/mmxnhsyyrb/page", {}, "get").then((res) => {
				this.vipData = res.data.list;
				this.vipInfo = this.vipData[this.userInfo.vipLevel - 1];
			});

			this.$EVENT.$on("changeLang", () => {
				this.lang = localStorage.getItem("lang") || "en";
			});
		},
	};
</script>

<style scoped lang="less">
	.home {
		padding: 90px 0;

		.notice {
			position: relative;
			width: 90%;
			left: 5%;
			display: flex;
			align-items: center;
			justify-content: center;
			height: 80%;
			top: 10%;

			.imgBox {
				position: relative;
				height: 100%;

				.noticeImg {
					width: 100%;
					max-height: 100%;
				}

				.noticeText {
					min-height: 400px;
					background: #fff;
					padding: 40px 15px 15px;
					box-sizing: border-box;
					word-wrap: break-word;
					word-break: break-all;
				}

				.closeImg {
					width: 25px;
					height: 25px;
					position: absolute;
					right: 5px;
					top: 5px;
				}
			}
		}

		.head {
			width: 100%;
			/*height: 90px;*/
			height: 60px;
			background: #111;
			position: fixed;
			top: 0;
			left: 0;
			z-index: 10;

			.menu {
				width: 28px;
				position: absolute;
				top: 10px;
				left: 10px;
			}

			.ripe {
				width: 150px;
				margin: 10px auto;
				display: block;
			}
		}

		.content {
			.userInfo {
				margin-top: -30px;

				.level {
					width: 50px;
					height: 50px;
					margin: 0 auto;
					color: #333;
					text-align: center;
					line-height: 50px;
					font-weight: bold;
				}

				.welcome {
					font-size: 20px;
					text-align: center;
					font-weight: bold;
					color: #fff;
					line-height: 1;
				}

				.name {
					font-size: 16px;
					text-align: center;
					color: #fff;
				}
			}

			.tipsBox {
				width: 100%;
				height: 32px;
				display: flex;
				align-items: center;
				padding: 0 20px;
				background: linear-gradient(to right, #5C258A, #3827C8);
				margin-top: 15px;

				img {
					width: 20px;
					margin-right: 20px;
				}

				p {
					flex: 1;
					color: #fff;
				}
			}

			.buttonBox {
				display: flex;

				.itemBox {
					margin-top: 15px;
					flex: 1;

					.imgBox {
						width: 64px;
						height: 67px;
						display: flex;
						align-items: center;
						justify-content: center;
						margin: 0 auto;
						background: linear-gradient(to bottom right, #5C258A, #3827C8);
						box-shadow: 5px 5px 5px 0 rgba(248, 68, 255, 0.3);
						border-radius: 15px;

						img {
							width: 80%;
						}
					}

					p {
						text-align: center;
						font-size: 13px;
						color: #fff;
						margin-top: 5px;
					}
				}
			}

			.levelBox {
				border: 2px solid #fff;
				margin: 10px;
				border-radius: 10px;
				overflow: hidden;
				background-color: #fff;

				.bg {
					height: 110px;
					background-image: linear-gradient(to bottom, #5C258A, #3827C8);
					position: relative;

					h2 {
						height: 30px;
						display: flex;
						align-items: center;
						padding: 0 10px;

						span {
							font-size: 14px;
							flex: 1;
							color: #fff;
						}

						a {
							font-size: 12px;
						}
					}

					.level {
						display: flex;
						bottom: 0px;
						position: absolute;

						.item {
							height: 70px;
							flex: 1;
							position: relative;
							margin: 0 10px;

							.lv {
								width: 65px;
								display: block;
								margin: 10px auto 0;
							}

							.lock {
								width: 20px;
								position: absolute;
								right: 10px;
								bottom: 5px;
							}
						}

						.on {
							border: 2px solid #36dfc8;
							border-bottom: 2px solid #fff;
							background: #fff;
							border-top-left-radius: 20px;
							border-top-right-radius: 20px;

							.lock {
								display: none;
							}
						}
					}
				}
			}

			.textBox {
				padding: 10px;
				color: #5c258a;

				h3 {
					font-weight: bold;
					font-size: 16px;
					margin-bottom: 8px;
				}

				ul {
					li {
						line-height: 1.6;

						&:before {
							content: "";
							width: 5px;
							height: 5px;
							background: #fff;
							display: inline-block;
							border-radius: 10px;
							vertical-align: middle;
							margin-right: 5px;
						}
					}
				}
			}
		}

		.otherBox {
			display: flex;
			border-top: 2px solid #fff;
			border-bottom: 2px solid #fff;
			padding: 10px 0;

			.item {
				flex: 1;

				img {
					display: block;
					width: 30px;
					margin: 0 auto 5px;
				}

				p {
					text-align: center;
				}
			}
		}
	}
</style>