<template>
  <div class="records">
    <Header title="Records" />
    <div class="container">
      <div class="tabBox">
        <div :class="['tab', tab === 0 ? 'on' : '']" @click="changeTab(0)">
          <span>{{ $t("ALL") }}</span>
        </div>
        <div :class="['tab', tab === 1 ? 'on' : '']" @click="changeTab(1)">
          <span>{{ $t("Submission") }}</span>
        </div>
        <div :class="['tab', tab === 2 ? 'on' : '']" @click="changeTab(2)">
          <span>{{ $t("Completed") }}</span>
        </div>
        <div :class="['tab', tab === 3 ? 'on' : '']" @click="changeTab(3)">
          <span>{{ $t("Pending") }}</span>
        </div>
      </div>
      <div class="content">
        <van-pull-refresh
          v-model="refreshing"
          @refresh="onRefresh"
          :loading-text="$t('loading')"
          loosing-text=" "
          pulling-text=" "
        >
          <van-list
            v-model="loading"
            :finished="finished"
            @load="initPage"
            :loading-text="$t('loading')"
            class="list"
          >
            <div
              class="box"
              v-for="item in dataList"
              :key="item.orderRecordsId"
            >
              <div class="titleBox">
                <div class="time">
                  {{ item.createTime | formatTime("YMDHMS") }}
                </div>
                <div class="buttonBox">
                  <span
                    class="Submission"
                    v-if="item.status === 'submission' && hasPendingStatus"
                    >{{ $t("waitforcompletion") }}</span
                  >
                  <span
                    class="SubmitNow"
                    @click="submitOrder(item)"
                    v-if="item.status === 'submission'"
                    >{{ $t("Submitnow") }}</span
                  >
                  <span class="Completed" v-if="item.status === 'completed'">{{
                    $t("Completed")
                  }}</span>
                  <span class="Pending" v-if="item.status === 'pending'"
                    >{{
					  $t("Pending")
					}}</span
                  >
                </div>
              </div>
              <div class="infoBox">
                <div class="top">
                  <img
                    :src="item.imgUrl"
                    class="img"
                    alt=""
                    style="width: 65px; height: 65px; border-radius: 5px;"
                  />
                  <div class="info">
                    <div class="number">
                      <!-- <span>N0.:{{ item.orderNumber }}</span> -->
                      <span
                        class="Pending"
                        v-if="item.status === 'submission' && hasPendingStatus"
                        >{{ $t("info40") }}</span
                      >
                      <span class="Pending" v-if="item.status === 'pending'"
                        >{{ $t("info40") }}</span
                      >
                      <!-- <img src="@/assets/images/copy.png" alt="" @click="copyed(item)"
												style="width: 13px; margin-left: 3px" /> -->
                    </div>
                    <div class="name">{{ item.title }}</div>
                  </div>
                </div>
                <div class="bottom">
                  <div class="item">
                    <div class="name">{{ $t("Totalamount") }}</div>
                    <div class="value">
						{{ $t("USDC") }} {{ (parseFloat(item.price) / 100).toFixed(2) }}
                    </div>
                  </div>
                  <div class="item">
                    <div class="name">Profit</div>
                    <div class="value">
						{{ $t("USDC") }} {{ (parseFloat(item.income) / 100).toFixed(2) }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </van-list>
        </van-pull-refresh>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: {
    Header,
  },
  data() {
    return {
      tab: 0,
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      params: {
        orderDetailEnum: "",
        pageNum: 1,
        pageSize: 10,
        total: 100,
      },
      dataList: [],
    };
  },
  computed: {
    // 判断是否存在状态为pending的订单
    hasPendingStatus() {
      return this.dataList.some((item) => item.status === "pending");
    },
  },
  methods: {
    copyed(item) {
      const orderNumber = item.orderNumber;
      var input = document.createElement("input"); // 创建input对象
      input.value = orderNumber; // 设置复制内容
      document.body.appendChild(input); // 添加临时实例
      input.select(); // 选择实例内容
      document.execCommand("Copy"); // 执行复制
      document.body.removeChild(input); // 删除临时实例
      this.toast(this.$t("CopySuccessfully"));
    },
    submitOrder(item) {
      this.confirm(
        "Optimize the data?",
        () => {
          this.getAjax("api/iishebajdnwfd/ccyewhsnlf/subOrder", {}, "get").then((res) => {
            this.showInfo(this.$t("Submittedsuccessfully"));
            this.onRefresh();
          });
        },
        null,
        {
			title: this.$t("Tips"),
			confirmButtonText: this.$t("Sure"),
			confirmButtonColor: "#333",
			cancelButtonText: this.$t("Cancel"),
        }
      );
    },
    changeTab(tab) {
      this.tab = tab;
      if (tab === 0) {
        this.params.orderDetailEnum = "";
      } else if (tab === 1) {
        this.params.orderDetailEnum = "submission";
      } else if (tab === 2) {
        this.params.orderDetailEnum = "completed";
      } else if (tab === 3) {
        this.params.orderDetailEnum = "pending";
      }
      this.onRefresh();
    },
    onRefresh() {
      this.params.pageNum = 1;
      this.params.pageSize = 10;
      this.params.total = 100;
      this.finished = false;
      this.dataList = [];
      this.initPage();
    },
    async initPage() {
      //获取记录列表
      if (this.dataList.length < this.params.total) {
        const res = await this.getAjax(
          "api/iishebajdnwfd/ccyewhsnlf/taskOrders",
          {
            ...this.params,
          },
          "get"
        );
        console.log(res);
        this.loading = false;
        this.refreshing = false;
        this.params.total = res.data.total;
        res.data.list.forEach((e) => {
          this.dataList.push(e);
        });
        this.params.pageNum++;
      } else {
        this.finished = true;
      }
    },
  },
  async mounted() {
    //获取记录列表
    this.dataList = [];
  },
};
</script>

<style lang="less" scoped>
.records {
  padding-top: 90px;

  .container {
    padding: 0 0 15px;

    .tabBox {
      padding: 0 10px 10px;
      border-bottom: 2px solid #3827c8;
      display: flex;
      justify-content: space-between;

      .tab {
        text-align: left;
        display: inline-block;

        span {
          display: inline-block;
          border-bottom: 3px solid #fff;
          padding: 5px;
          color: #fff;
          font-size: 15px;
        }
      }

      .on {
        span {
          font-weight: bold;
          color: #fff;
          border-bottom: 3px solid #3827c8;
        }
      }
    }

    .content {
      padding: 10px;

      .box {
        margin-bottom: 10px;

        .titleBox {
          display: flex;
          align-items: center;

          .time {
            flex: 1;
            font-size: 13px;
            color: #e1e1e1;
          }

          .buttonBox {
            .Submission {
              width: 130px;
              height: 22px;
              line-height: 16px;
              border: 2px solid #ffba62;
              border-radius: 4px;
              color: #ffba62;
              text-align: center;
              font-size: 12px;
              display: inline-block;
              margin-left: 10px;
            }

            .SubmitNow {
              width: 90px;
              height: 22px;
              line-height: 16px;
              border: 2px solid #facc01;
              background: #facc01;
              border-radius: 4px;
              color: #333;
              text-align: center;
              font-size: 12px;
              display: inline-block;
              margin-left: 10px;
            }

            .Completed {
              width: 90px;
              height: 22px;
              line-height: 16px;
              border: 2px solid #58c069;
              border-radius: 4px;
              color: #58c069;
              text-align: center;
              font-size: 12px;
              display: inline-block;
              margin-left: 10px;
            }

            .Pending {
              width: 90px;
              height: 22px;
              line-height: 16px;
              border: 2px solid #ff4343;
              border-radius: 4px;
              color: #ff4343;
              text-align: center;
              font-size: 12px;
              display: inline-block;
              margin-left: 10px;
            }
          }
        }

        .infoBox {
          border: 1px solid #fff;
          border-radius: 8px;
          padding: 10px;
          margin-top: 5px;
          box-shadow: 0 2px 2px #5C258A;

          .top {
            border-bottom: 1px solid #fff;
            display: flex;
            padding-bottom: 10px;

            .img {
              width: 300px;
              height: 300px;
            }

            .info {
              flex: 1;
              margin-left: 15px;

              .number {
                display: flex;
                align-items: center;
                justify-content: flex-end;

                span {
                  color: #ff4343;
                  font-size: 14px;
                }
              }

              .name {
                font-size: 14px;
                font-weight: bold;
                margin-top: 5px;
              }
            }
          }

          .bottom {
            display: flex;
            padding-top: 15px;

            .item {
              flex: 1;

              .name {
                font-size: 12px;
                color: #e1e1e1;
              }

              .value {
                font-weight: bold;
                margin-top: 5px;
                color: #fff;
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}
</style>