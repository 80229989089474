<template>
  <div class="withdraw">
    <Header
      :title="$t('Withdraw')"
      rightNav="History"
      @handleClickRightNav="handleClickRightNav"
    />
    <div class="content">
      <div class="accountBox">
        <h3>{{ $t("accountAmount") }}</h3>
        <div class="money">
          <span>{{ $t("USDC") }}</span>
          <em>{{ userInfo.walletAmount }}</em>
        </div>
        <p>{{ $t("info10") }}</p>
      </div>
      <div class="title">
        <span>{{ $t("WithdrawMethod") }}</span>
      </div>
      <div class="tips">
        {{ $t("info11") }}
      </div>
      <div class="title">
        <span>{{ $t("WithdrawAmount") }}</span>
        <div class="button" @click="withdrawAll()">{{ $t("WithdrawAll") }}</div>
      </div>
      <div class="inputBox">
        <input
          type="number"
          class="input"
          v-model="choiceMoney"
          :placeholder="$t('TypewithdrawAmount')"
        />
      </div>
      <div class="title">
        <span>{{ $t("SecurityPIN") }}</span>
      </div>
      <div class="inputBox">
        <input
          type="password"
          class="input"
          v-model="pin"
          :placeholder="$t('TypeSecurityPIN')"
        />
      </div>
      <div class="df_btn" @click="Submit()">{{ $t("Submit") }}</div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: { Header },
  data() {
    return {
      choiceMoney: "",
      pin: "",
      userInfo: {},
    };
  },
  methods: {
    withdrawAll() {
      this.choiceMoney = this.userInfo.walletAmount;
    },
    handleClickRightNav() {
      this.$router.push("/withdrawHistory");
    },
    clickItem(item) {
      this.choiceMoney = item.money;
    },
    async Submit() {
      if (!this.choiceMoney || !this.pin) {
        this.showInfo(this.$t("info12"));
        return;
      }

      //查询是否设置了提款密码
      const res = await this.getAjax("api/iishebajdnwfd/ccyewhsnlf/isSetSecPwd", {}, "get");
      if (res.data === "Not") {
        this.showInfo(this.$t("info13"));
        return;
      }

      this.getAjax(
        "api/iishebajdnwfd/ccyewhsnlf/cashOut",
        {
          cashOutAmount: parseFloat(this.choiceMoney) * 100,
          secPwd: this.pin,
        },
        "post"
      ).then((res) => {
        this.showInfo(this.$t("Submittedsuccessfully"));
        this.initUserInfo();
      });
    },
    initUserInfo() {
      this.getAjax("api/iishebajdnwfd/ccyewhsnlf/getBalance", {}, "get").then((res) => {
        this.hideLoading();
        this.userInfo = res.data;
      });
    },
  },
  mounted() {
    //获取用户信息
    this.initUserInfo();
  },
};
</script>

<style lang="less" scoped>
.withdraw {
  padding-top: 90px;
  .content {
    padding: 0 10px 10px;
    line-height: 1.6;
    .accountBox {
      width: 100%;
      height: 135px;
      background: linear-gradient(to bottom right, #5C258A, #3827C8);
      background-size: cover;
      border-radius: 10px;
      padding: 10px;
      box-sizing: border-box;
      box-shadow: 5px 5px 5px 0 rgba(248, 68, 255, 0.3);
    
      h3 {
        color: #fff;
        margin-top: 10px;
        font-size: 12px;
        font-weight: normal;
      }
      .money {
        color: #fff;
        font-weight: bold;
        margin-top: 10px;
        span {
          font-size: 15px;
        }
        em {
          font-size: 22px;
          margin-left: 10px;
        }
      }
      p {
        font-size: 12px;
        text-align: center;
        color: #fff;
        margin-top: 20px;
      }
    }
    .title {
      display: flex;
      align-items: center;
      margin-top: 15px;
      span {
        flex: 1;
        color: #fff;
        font-weight: bold;
      }
      .button {
        width: 100px;
        height: 20px;
        line-height: 18px;
        text-align: center;
        color: #3827c8;
        font-size: 12px;
        border-radius: 3px;
        background: #fff;
        border: 1px solid #fff;
      }
    }
    .tips {
      font-size: 12px;
    }
    .inputBox {
      height: 46px;
      border-radius: 3px;
      padding: 0 10px;
      background: #fff;
      margin-top: 5px;
      border: 2px solid #c4e8ef;
      .input {
        height: 100%;
        width: 100%;
        color: #333;
        font-size: 14px;
        background: #fff;
        outline: none;
        border: 0;
      }
    }
  }
}
</style>
