import Vue from 'vue'
import App from './App.vue'
import router from './router'
import request from '@/assets/api/request'
import defaultFunc from '@/assets/utils/defaultFunc' //默认方法
import '@/assets/utils/filter.js'
import '@/assets/less/common.less'
import Vant from 'vant'
import 'vant/lib/index.css'
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

const lang = localStorage.getItem("lang") || "en";

const i18n = new VueI18n({
  locale: lang, // 默认语言
  messages: {
    'en': require('./lang/en.json'),
    'xb': require('./lang/xb.json'),
    'de': require('./lang/de.json'),
  }
})

Vue.use(Vant)

Vue.config.productionTip = false
const { getAjax, confirm, toast, success, info,defaultVue, showLoading,hideLoading } = defaultFunc
// 挂载request
Vue.prototype.request = request
Vue.prototype.getAjax = getAjax
Vue.prototype.confirm = confirm
Vue.prototype.toast = toast
Vue.prototype.success = success
Vue.prototype.showInfo = info
Vue.prototype.$EVENT = defaultVue
Vue.prototype.showLoading = showLoading
Vue.prototype.hideLoading = hideLoading

new Vue({
  router,
  i18n,
  render: (h) => h(App)
}).$mount('#app')

router.beforeEach((to, from, next) => {
  const name = to.meta.name;
  document.title = name;
  next();
})

