<template>
  <div class="rechargeHistory">
    <Header :title="$t('RechargeHistory')" />
    <div class="content">
      <van-pull-refresh
        v-model="refreshing"
        @refresh="onRefresh"
        :loading-text="$t('loading')"
        loosing-text=" "
        pulling-text=" "
      >
        <van-list
          v-model="loading"
          :finished="finished"
          @load="initPage"
          :loading-text="$t('loading')"
          class="list"
        >
          <div class="box" v-for="item in dataList" :key="item.walletRecordId">
            <img src="@/assets/images/slide1.png" style="width: 50px" alt="" />
            <div class="timeBox">
              <h2>{{$t('deposit')}}</h2>
              <p>{{ item.changeTime | formatTime("YMDHMS") }}</p>
            </div>
            <div class="buttonBox">
              <div class="button">{{ item.changeType }}</div>
              <p>{{ (parseFloat(item.changeBalance) / 100).toFixed(2) }}</p>
            </div>
          </div>
        </van-list>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
export default {
  components: { Header },
  data() {
    return {
      dataList: [],
      loading: false,
      finished: false,
      refreshing: false,
      params: {
        pageNum: 1,
        pageSize: 10,
        total: 100,
      },
    };
  },
  methods: {
    onRefresh() {
      this.params = {
        pageNum: 1,
        pageSize: 10,
        total: 100,
      };
      this.finished = false;
      this.dataList = [];
      this.initPage();
    },
    async initPage() {
      //获取充值记录
      if (this.dataList.length < this.params.total) {
        const res = await this.getAjax(
          "api/iishebajdnwfd/ccyewhsnlf/recharge",
          { ...this.params },
          "get"
        );
        this.loading = false;
        this.refreshing = false;
        this.params.total = res.data.total;
        res.data.list.forEach((e) => {
          this.dataList.push(e);
        });
        this.params.pageNum++;
      } else {
        this.finished = true;
      }
    },
  },
  mounted() {
    //获取充值记录
    this.dataList = [];
  },
};
</script>

<style lang="less" scoped>
.rechargeHistory {
  padding-top: 90px;
  .content {
    padding: 0 10px 10px;
    line-height: 1.6;
    .list {
      height: 100%;
      .box {
        padding: 15px 10px;
        display: flex;
        align-items: center;
        border-top: 1px solid #39c8c1;
        &:first-child {
          border-top: 0;
        }
        img {
          margin-right: 5px;
        }
        .timeBox {
          flex: 1;
          h2 {
            font-size: 16px;
            margin-top: 5px;
            color: #26abc3;
          }
          p {
            font-size: 12px;
            margin-top: 5px;
            color: #666;
          }
        }
        .buttonBox {
          padding-top: 10px;
          .button {
            width: 75px;
            height: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #fff;
            font-size: 14px;
            background: #00cc66;
            border-radius: 3px;
          }
          p {
            font-size: 16px;
            margin-top: 5px;
            color: #2faec6;
            font-weight: bold;
            text-align: right;
          }
        }
      }
    }
  }
}
</style>