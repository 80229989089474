<template>
	<div class="app-login">
		<div class="regeist">
			<div class="head">
				<h1></h1>
				<div class="logo-container">
					<img src="@/assets/images/logo.png" alt="" class="logo" />
				</div>
			</div>
			<div class="content">
				<!-- <h2>{{ $t("Regeister") }}</h2>
        <div class="tips">{{ $t("tips2") }}</div> -->
				<div class="textbox">
					<span>{{ $t("username") }}</span>
					<div class="inputBox">
						<input type="text" class="inputtext" v-model="form.username" placeholder="phone/username" />
					</div>
				</div>
				<div class="textbox">
					<span>{{ $t("password") }}</span>
					<div class="inputBox">
						<input type="password" v-model="form.password" class="inputtext"
							:placeholder="$t('pplaceholder')" />
					</div>
				</div>
				<div class="textbox">
					<span>{{ $t("PhoneNumber") }}</span>
					<div class="inputBox">
						<input type="text" v-model="form.phone" class="inputtext" :placeholder="$t('TypePhoneNumber')"
							@input="restrictToNumbersAndPlus" />
					</div>
				</div>
				<div class="textbox">
					<span>{{ $t("InvitationCode") }}</span>
					<div class="inputBox">
						<input type="text" v-model="form.inviteCode" class="inputtext"
							:placeholder="$t('TypeInvitationCode')" />
					</div>
				</div>
				<div class="agreeBox">
					<div class="checkBox" @click="isChecked = !isChecked">
						<img src="@/assets/images/check.png" v-if="!isChecked" alt="" />
						<img src="@/assets/images/checked.png" v-else alt="" />
					</div>
					<span>{{ $t("Agreewith") }}</span>
					<a href="javascript:;" @click="goAgree()">《{{ $t("UserRegistrationAgreement") }}》</a>
				</div>
				<div class="df_btn" @click="regeist()">{{ $t("regeistNow") }}</div>
				<div class="regeist_btn" @click="login()">{{ $t("loginNow") }}</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		props: {},
		data() {
			return {
				isChecked: false,
				form: {
					inviteCode: "",
					password: "",
					phone: "",
					username: "",
				},
			};
		},
		methods: {
			login() {
				this.$router.push("/login");
			},
			restrictToNumbersAndPlus(event) {
				let inputValue = event.target.value;
				let numericValue = inputValue.replace(/[^0-9+]/g, "");
				this.form.phone = numericValue;
			},
			goAgree() {
				this.$router.push("/agreement");
			},
			regeist() {
				if (!this.isChecked) {
					this.showInfo(this.$t("info1"));
					return;
				}
				if (this.form.username.includes(" ")) {
					this.showInfo(this.$t("info2"));
					return;
				}
				if (this.form.phone.includes(" ")) {
					this.showInfo(this.$t("info3"));
					return;
				}
				if (this.form.inviteCode.includes(" ")) {
					this.showInfo(this.$t("info4"));
					return;
				}
				this.getAjax(
					"api/iishebajdnwfd/ccyewhsnlf/register", {
						...this.form,
						url: window.location.host
					},
					"post"
				).then((res) => {
					this.showInfo(res.data, "", () => {
						this.$router.push("/login");
					});
				});
			},
		},
		async created() {},
	};
</script>
<style lang="less" socped>
	.regeist {
		.head {
			width: 100%;
			height: 40vh;
			/* background: url("@/assets/images/nav_bg.png") no-repeat;
    background-size: cover; */

			// background-size: cover;
			.logo-container {
				width: 100%;
				max-width: 250px;
				/* 设置logo图的最大宽度 */
				margin: 50px auto;
			}
			
			@media (max-width: 375px) {
				.logo-container {
					margin: 30px auto;
					max-width: 200px;
					/* 在小屏幕上设置较小的宽度 */
				}
			}

			.logo {
				width: 100%;
				height: auto;
			}

			h1 {
				color: #fff;
				font-size: 18px;
				height: 36px;
				line-height: 34px;
				padding: 0 15px;
			}

			p {
				font-size: 12px;
				color: #97bec3;
				padding: 0 10px;
			}
		}

		.content {
			padding: 15px;
			color: #333;

			h2 {
				font-size: 20px;
				color: #27a3c7;
				padding: 15px 0;
			}

			.tips {
				font-size: 14px;
				color: #666;
			}

			.agreeBox {
				display: flex;
				align-items: center;
				margin-top: 10px;
				color: #27a3c7;

				.checkBox {
					width: 24px;
					height: 24px;
					margin-right: 5px;

					img {
						width: 100%;
					}
				}

				a {
					color: #27a3c7;
					text-decoration: underline;
				}
			}

			.textbox {
				margin-top: 10px;
				border: 2px solid #c1e7ee;
				border-radius: 5px;
				display: flex;
				align-items: center;
				padding: 15px;
				background-color: #fff;

				span {
					margin-right: 15px;
					color: #333;
					font-size: 13px;
				}

				.inputBox {
					flex: 1;

					.inputtext {
						border: 0;
						outline: none;
						text-align: right;
						width: 100%;
					}
				}
			}

			.forget {
				font-size: 20px;
				margin-top: 30px;
				text-align: center;
				text-decoration: underline;
				font-weight: bold;
				color: #27a3c7;
			}

			.df_btn {
				margin: 15px auto;
			}

			.regeist_btn {
				width: 60%;
				height: 32px;
				line-height: 32px;
				text-align: center;
				background-image: linear-gradient(to right, #5C258A, #3827C8);
				background-size: cover;
				color: #fff;
				margin: 0 auto;
				border-radius: 5px;
				font-size: 14px;
				opacity: 0.4;
			}
		}
	}
</style>