import axios from 'axios'
import config from '../api/config'
import { Dialog, Toast, Notify } from 'vant'
import Vue from 'vue'

const defaultVue = new Vue();

const getAjax = async (url, requestParams, method) => {
  return new Promise((resolve) => {
    let params = {}
    let data = {}
    if (method === 'get') {
      params = requestParams
    } else {
      data = requestParams
    }

    let requstUrl = config.base + url;
    if(requstUrl.includes('?')){
      requstUrl = requstUrl+"&lang="+localStorage.getItem('lang') || 'en'
    }else{
      requstUrl = requstUrl+"?lang="+localStorage.getItem('lang') || 'en'
    }
    axios({
      method,
      url: requstUrl,
      responseType: 'json', /// 设置相应头
      // `headers`是要发送的自定义请求头
      headers: {
        'Content-Type': 'application/json',
        'x-app-secret': 'c2737c56837d1032d413055483330196',
        'x-access-token': localStorage.getItem('token')
      },
      data,
      params
    }).then((res) => {
      if (res.success) {
        resolve(res)
      } else {
        info(res.message)
      }
    })
  })
}

const confirm = (title, sfun, cfun, options) => {
  Dialog.confirm({
    message: title || '确定提交吗？',
    ...options
  })
    .then(() => {
      // on confirm
      sfun && sfun()
    })
    .catch(() => {
      // on cancel
      cfun && cfun()
    })
}

const info = (message, title, sfun) => {
  Dialog.alert({
    title: title || "Tips",
    message: message || 'Info',
    confirmButtonColor: '#333',
    confirmButtonText: 'Sure'
  }).then(() => {
    // on confirm
    sfun && sfun()
  })
}

const success = (title) => {
  Notify({ type: 'success', message: title || '成功' })
}

const toast = (title) => {
  Toast(title || '提示内容')
}

const showLoading = () => {
  defaultVue.$emit('loading', true)
}
const hideLoading = () => {
  defaultVue.$emit('loading', false)
}

export default { getAjax, confirm, toast, success, info, defaultVue, showLoading, hideLoading }
