<template>
	<div class="app-login">
		<div class="login">
			<div class="head">
				<h1></h1>
				<div class="logo-container">
					<img src="@/assets/images/logo.png" alt="" class="logo" />
				</div>
			</div>
			<div class="content">
				<!-- <h2>{{ $t("login") }}</h2>
				<div class="tips">{{ $t("tips") }}</div> -->
				<div class="textbox">
					<span>{{ $t("username") }}</span>
					<div class="inputBox">
						<input type="text" class="inputtext" v-model="username" :placeholder="$t('uplaceholder')" />
					</div>
				</div>
				<div class="textbox">
					<span>{{ $t("password") }}</span>
					<div class="inputBox">
						<input type="password" v-model="password" class="inputtext" :placeholder="$t('pplaceholder')" />
					</div>
				</div>
				<div class="forget" @click="forgetPwd()"><!-- {{ $t("forgetPwd") }}? --></div>
				<div class="df_btn" @click="login()">{{ $t("loginNow") }}</div>
				<div class="regeist_btn" @click="regeist()">{{ $t("regeistNow") }}</div>
			</div>
			<van-action-sheet v-model="showSheet" cancel-text="Cancel" close-on-click-action>
				<template #default="{ action }">
					<div v-for="(action, index) in actions" :key="index" @click="handleActionClick(action)"
						class="action-div">
						<img v-if="action.image" :src="action.image" class="action-image" alt="" />
						{{ action.name }}
					</div>
				</template>
			</van-action-sheet>
		</div>
	</div>
</template>

<script>
	export default {
		props: {},
		data() {
			return {
				showSheet: false,
				actions: [],
				password: "", // 最后一个属性后面没有逗号
				username: "", // 最后一个属性后面没有逗号
			};
		},
		created() {
			// 获取客服列表
			this.getAjax('api/iwnaxhvbewk/owrnabxjh/getCustomer?url=' + window.location.host, {}, 'get')
				.then(res2 => {
					this.actions = res2.data
						.filter(e => !!e.name)
						.map((item, index) => {
							let imageSrc;
							switch (item.name.toLowerCase()) {
								case 'line':
									imageSrc = require('../assets/images/line.png');
									break;
								case 'whatsapp':
									imageSrc = require('../assets/images/whatsapp.jpg');
									break;
								case 'telegram':
									imageSrc = require('../assets/images/telegram.png');
									break;
								default:
									imageSrc = require(
									'../assets/images/whatsapp.jpg'); // Default image if none matches
							}
							return {
								name: item.name,
								image: imageSrc,
								value: item.value
							};
						});
				});
		},
		methods: {
			async login() {
				const res = await this.getAjax(
					"api/iishebajdnwfd/ccyewhsnlf/login", {
						password: this.password,
						username: this.username,
					},
					"post"
				);
				if (res.data) {
					localStorage.setItem("userInfo", JSON.stringify(res.data));
					localStorage.setItem("firstLogin", true);
					this.$router.push("/home");
				}
			},
			regeist() {
				this.$router.push("/regeist");
			},
			forgetPwd() {
				this.showSheet = true;
			},
			selectSheet(opt) {
				window.open(opt.value, "_blank");
			},
			handleActionClick(action) {
				// 执行页面跳转操作
				window.location.href = action.value;
			},
		},
	};
</script>
<style lang="less" socped>
	.action-div {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin-left: auto;

		height: 50px;
	}

	.action-image {
		width: 40px;
		border-radius: 20%;
		margin-right: 10px;
	}

	.login {
		.head {
			width: 100%;
			height: 40vh;
			/* background: url("@/assets/images/nav_bg.png") no-repeat;
			background-size: cover; */

			// background-size: cover;
			.logo-container {
				width: 100%;
				max-width: 250px;
				/* 设置logo图的最大宽度 */
				margin: 50px auto;
			}

			@media (max-width: 375px) {
				.logo-container {
					margin: 30px auto;
					max-width: 200px;
					/* 在小屏幕上设置较小的宽度 */
				}
			}

			.logo {
				width: 100%;
				height: auto;
			}


			h1 {
				color: #fff;
				font-size: 18px;
				height: 36px;
				line-height: 34px;
				padding: 0 15px;
			}

			p {
				font-size: 12px;
				color: #97bec3;
				padding: 0 10px;
			}
		}

		.content {
			padding: 15px;
			margin: 15px;
			color: #333;
			h2 {
				font-size: 20px;
				color: #27a3c7;
				padding: 15px 0;
			}

			.tips {
				font-size: 14px;
				color: #666;
			}

			.textbox {
				margin-top: 10px;
				border: 2px solid #c1e7ee;
				border-radius: 5px;
				display: flex;
				align-items: center;
				padding: 15px;
				background-color: #fff;

				span {
					margin-right: 15px;
					color: #333;
					font-size: 13px;
				}

				.inputBox {
					flex: 1;

					.inputtext {
						border: 0;
						outline: none;
						text-align: right;
						width: 100%;
					}
				}
			}

			.forget {
				font-size: 20px;
				margin-top: 30px;
				text-align: center;
				text-decoration: underline;
				font-weight: bold;
				color: #27a3c7;
			}

			.df_btn {
				margin: 15px auto;
			}

			.regeist_btn {
				width: 60%;
				height: 32px;
				line-height: 32px;
				text-align: center;
				background-image: linear-gradient(to right, #5C258A, #3827C8);
				background-size: cover;
				color: #fff;
				margin: 0 auto;
				border-radius: 5px;
				font-size: 14px;
				opacity: 0.4;
			}
		}
	}
</style>