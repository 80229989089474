import { render, staticRenderFns } from "./changePayPwd.vue?vue&type=template&id=32aec732&scoped=true"
import script from "./changePayPwd.vue?vue&type=script&lang=js"
export * from "./changePayPwd.vue?vue&type=script&lang=js"
import style0 from "./changePayPwd.vue?vue&type=style&index=0&id=32aec732&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32aec732",
  null
  
)

export default component.exports